.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  padding: 2vw;
  overflow: auto;
}
.monitor-offline {
  width: 14vw;
}

.vitals-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92%;

  .graph-data {
    display: flex;
    flex-direction: column;
    height: 84%;
    overflow: auto;
    // background-color: red;
    padding: 7px 15px;
    overflow-x: hidden;

    .graph-vital-wrap {
      display: flex;
      flex-direction: row;
      width: 90vw;
      margin-bottom: 0.5vw;

      .graph-container {
          width: 60%;
        .ecg-graph-1 {
          height: 5vw;
          width: 100%;
        }
  
        .ecg-graph-2 {
          height: 5vw;
        }
  
        .pleth-graph {
          height: 7vw;
        }
  
        .resp-graph {
          height: 7vw;
        }
        .etco2-graph{
          height: 7vw;
        }
        .ibp1-graph{
          height: 7vw;
        }
        .ibp2-graph{
          height: 7vw;
        }
        .select-vital-ecg-container {
          display: flex;
          gap: 3px;
          position: relative;
          cursor: pointer;
          width: 50px;
  
          img {
            accent-color: #268D3D;
          }
  
          .selected-vital-ecg-name {
            font-weight: 400;
            font-size: 0.833vw;
            line-height: 120%;
            width: 1.563vw;
            color: #299842;
          }
  
          .vital-graph-options {
            position: absolute;
            top: 20px;
            width: 126px;
            // height: 372px;
            padding: 10px;
            background: rgba(52, 52, 52, 0.5);
            border: 1px solid #404040;
            backdrop-filter: blur(40px);
            border-radius: 6px;
            z-index: 10;
  
            .common-vital-ecg {
              padding: 0.521vw;
              font-weight: 400;
              font-size: 0.729vw;
              line-height: 1.875vw;
              &:hover{
                background-color: #9F33FF;
              }
            }

            .vital-ecg-active {
              background-color: #6D1DF0;
              border-radius: 4px;
            }
          }
        }
      }

    .data-container {
      width: 18% !important;
      display: grid;
      margin-left: 0.6vw;
      grid-column: 5/6;
      grid-template-rows: 0.5fr 0.5fr auto;
      grid-gap: 5px;
      .vital-ecg-option {
        display: grid;
        width: 100%;
        color: #47ff7b;
        grid-template-columns: 0.3fr 2fr 1.6fr;
        gap: 0.6vw;

        .ecg-param-col {
          .ecg-entry {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            height: 1.56vw;
            gap: 0.156vw;

            .ecg-param-name {
              font-weight: 400;
              font-size: 0.625vw;
              line-height: 120%;
              // width: 10px;
            }

            .ecg-param-val {
              font-weight: 500;
              font-size: 0.833vw;
              line-height: 100%;
              letter-spacing: -0.05em;
            }
          }
        }
      }

      .vital-data {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column: 1/5;
        display: grid;
        justify-content: center;
        align-items: center;
        .vital-unit {
          font-size: 0.625vw;
          line-height: 120%;
        }
        .vital-name-container {
          grid-column: 1/2;
          grid-row: 1/2;

          .vital-name {
            font-weight: 500;
            font-size: 0.833vw;
            line-height: 120%;
          }
        }

        .vital-value-container {
          grid-column: 2/6;
          grid-row: 1/4;
        }

        .vital-lb-container {
          grid-column: 1/2;
          grid-row: 3/4;
        }

        .vital-rt-container {
          grid-column: 6/8;
          grid-row: 1/2;
        }

        .vital-rb-container {
          grid-column: 6/8;
          grid-row: 3/4;
          .mean-ibp{
            font-weight: 500;
            font-size: 1.042vw;
            line-height: 120%;
            letter-spacing: -0.05em;
          }
          .rb-vital-name {
            font-size: 0.625vw;
            line-height: 120%;
          }
        }

        .rt-vital-name,
        .rb-vital-value {
          font-weight: 500;
          font-size: 0.625vw;
          line-height: 120%;
        }

        .vital-value-container {
          font-weight: 500;
          font-size: 4.167vw;
          line-height: 110%;
          text-align: center;
        }

        .source-ecg {
          font-weight: 400;
          font-size: 8px;
          line-height: 11px;
        }
      }

      .ecg {
        color: #47ff7b;
        // gap: 10px;
      }

      .spo2 {
        color: #47f4ff;
      }

      .resp {
        color: #ffed47;
      }
      .etco2{
        color: #6D1DF0;
      }

      .ibp1{
        color: #F01DCE;
        .ibp1-val-main{
          font-weight: 500;
          font-size: 2.292vw;
          line-height: 120%;
          letter-spacing: -0.05em;
        }
      }
      .ibp2{
        color: #F01DCE;
        .ibp2-val-main{
          font-weight: 500;
          font-size: 2.292vw;
          line-height: 120%;
          letter-spacing: -0.05em;
        }
      }
    }
  }

    .progress-bar-container {
      .wrapper {
        // border: 1px solid #808080;
        height: 7px;
        // background-color: red;
      }

      .container {
        // background-color: green;
        height: 7px;
        border: 1px solid #808080;

        > div {
          background: white !important;
          height: 7px !important;
        }
      }

      .barCompleted {
        background-color: #ffffff;
        width: attr(completed);
        border: 1px solid #ffffff;
        height: 7px;
      }
    }
  }
  .footer-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 13%;

    .temp-container {
      grid-column: 1/3;
      grid-row: 4/5;
      // background-color: blue;
      display: grid;
      // grid-template-columns: repeat(4, 1fr);
      grid-template-columns: 0.5fr 1fr 1fr 1fr;
      grid-template-rows: repeat(2, 1fr);
      column-gap: 2vw;
      // grid-template-rows: 0.5fr 1fr 1fr;
      // text-align: center;
      justify-content: center;
      align-items: center;
      // color: #999999;
      padding: 0.16vw 0.7vw;

      .temp-name-container {
        grid-column: 1/2;
        grid-row: 1/2;
        align-self: flex-start;

        .temp-name {
          font-weight: 500;
          font-size: 0.833vw;
          line-height: 120%;
        }

        .temp-value {
          font-weight: 500;
          font-size: 2.083vw;
          line-height: 120%;
          letter-spacing: -0.05em;
        }
      }

      .temp-hl-container {
        grid-column: 1/2;
        grid-row: 2/3;
        // align-self: flex-end;

        .temp-high,
        .temp-low {
          font-weight: 400;
          font-size: 0.625vw;
          line-height: 120%;
        }
      }

      .temp-t1-container {
        grid-column: 2/3;
        grid-row: 1/3;
        align-self: center;
        text-align: center;
      }

      .temp-name {
        font-weight: 400;
        font-size: 0.625vw;
        line-height: 120%;
      }

      .temp-value {
        font-weight: 500;
        font-size: 2.083vw;
        line-height: 120%;
      }

      .temp-t2-container {
        grid-column: 3/4;
        grid-row: 1/3;
        align-self: center;
        text-align: center;
      }

      .temp-td-container {
        grid-column: 4/5;
        grid-row: 1/3;
        text-align: center;

        .td-name {
          font-weight: 400;
          font-size: 0.625vw;
          line-height: 120%;
        }

        .td-value {
          font-weight: 500;
          font-size: 1.25vw;
          line-height: 120%;
        }
      }
    }

    .bp-container {
      grid-column: 4/6;
      grid-row: 4/5;
      display: grid;
      grid-template-columns: repeat(3, 0.6fr);
      grid-template-rows: repeat(2, 1fr);
      justify-content: center;
      align-items: center;
      padding: 0.16vw 1vw;

      .nibp-name-container {
        grid-column: 1/2;
        grid-row: 1/2;
        align-self: flex-start;

        .nibp-name {
          font-weight: 500;
          font-size: 0.833vw;
          line-height: 120%;
        }

        .nibp-unit {
          font-weight: 400;
          font-size: 0.625vw !important;
          line-height: 120%;

        }

      .progress-bar-container {
        display: grid;
        grid-template-columns: 0.3fr 1fr;
        grid-row: 2/3;
        text-align: center;
        }

        .progress-bar {
          grid-column: 2/12;
          padding: 2px 10px;
        }
      }

      .nibp-name-container {
        grid-column: 1/2;
        grid-row: 1/2;
        align-self: flex-start;

        .nibp-name {
          font-weight: 500;
          font-size: 0.833vw;
          line-height: 120%;
        }

        .nibp-value {
          font-weight: 500;
          font-size: 2.083vw;
          line-height: 120%;
          letter-spacing: -0.05em;
        }
      }

      .nibp-hl-container {
        grid-column: 1/2;
        grid-row: 2/3;
        // align-self: flex-end;

        .nibp-high,
        .nibp-low {
          font-weight: 400;
          font-size: 0.625vw;
          line-height: 120%;
        }
      }

      .nibp-data-container {
        // display: grid;
        // grid-template-columns: repeat(5, 1fr);
        // align-content: center;
        // grid-row: 3/3;
        grid-column: 2/3;
        grid-row: 1/3;
        align-self: center;

        .nibp-data {
          text-align: center;
          font-weight: 500;
          font-size: 3vw;
          line-height: 150%;
          letter-spacing: -0.05em;
          align-self: center;
        }
      }

      .nibp-avg-data {
        font-weight: 500;
        font-size: 2.083vw;
        line-height: 120%;
        align-self: center;
        grid-column: 3/4;
        grid-row: 1/3;
        align-self: center;
        justify-self: flex-end;
      }
    }
  }
  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.offline {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
    padding: 2vw;
    overflow: auto;
}
  .not-in-use-imge {
    width: 14vw;
  }
}