.bed-container {
    min-height: 93%;
    display: flex;
    width: 96vw;

    .bed-grid-data-container {
        width: 100%;
        margin: 12px 16px 20px 16px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px 10px;
        height: fit-content;
        overflow-y: scroll;
        overflow-x: hidden;
        // transition: 2s;
        > .bed-card {
            //   border: solid 1px red;
            height: 22%;
            min-height: 180px;
        }
    }

    .left-panel {
        width: 24%;
        display: flex;
        gap: 0.833vw;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .right-panel {
        width: 75%;
        // overflow: scroll;
        border: 1px solid #4D4D4D;
        border-top: 0px;
        max-height: 100%;
        
    }

    .bed-list-data-container {
        margin-top: 26px;
        padding-bottom: 10px;
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // grid-template-rows: 50% 50%;
        // grid-template-columns: 33% 66%;
        // grid-gap: 23px 18px;
        height: 100%;
        // overflow: scroll;

        // .bed-list-card {
        //   height: 100%;
        //   overflow: scroll;
        //   display: grid;
        //   grid-gap: 23px;
        //   grid-row: 1/3;

        //   .bed-card {
        //     //   border: solid 1px red;
        //     // height: 22%;
        //     min-height: 160px;
        //     grid-column: 1/2;
        //   }
        // }

        // .bed-list-data {
        //   grid-column: 2/4;
        //   grid-row: 1/3;
        //   // background-color: red;
        //   height: 100%;
        //   // overflow: scroll;
        // }
    }
}