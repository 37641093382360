.patient-book-page {
  height: 100%;
  width: 100%;
  overflow: scroll;

  .patient-book-body-container {
    .patient-book-header{
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-top: 10px;
        position: relative;
        top: 1%;
    }
    padding: 16px 36px 0px 16px;
    // background-color: red;
    max-height: calc(100% - 80px);
    max-width: calc(100% - 80px);
    height: 90.3%;
    width: 94.5%;
    float: right;
    box-sizing: border-box;
    margin-top: 56px;
  }
  .toggle-filter-container {
    margin-top: 26px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .toggle-switch-container {
      min-width: 225px;

      .grid-view-text {
        margin-right: 18px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        vertical-align: middle;
      }

      .list-view-text {
        margin-left: 18px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        vertical-align: middle;
      }
    }

    .filter-container {
      // grid-column: 6/6;
      // justify-self: end;
      grid-column: 2/2;
      justify-self: left;
      margin-left: 20px;
    }

    .add-column-container {
      grid-column: 6/6;
      justify-self: end;
      position: relative;

      .add-column-button {
        background: #6D1DF0;
        border-radius: 6px;
        padding: 13px 12px;
        font-weight: 600;
        font-size: 16px;
        line-height: 12px;
        text-align: center;
        cursor: pointer;
        min-width: 134px;
      }

      .add-column-option-container {
        position: absolute;
        z-index: 1000;
        top: 110%;
        background-color: #333333;
        width: 100%;
        padding: 4px;
        box-sizing: border-box;
        border-radius: 8px;

        .option-heading {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          padding: 5px 10px 5px 16px;
        }

        .add-column-option {
          display: flex;
          text-align: left;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          padding: 10px 10.5px 10px 16px;

          .option-label {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
