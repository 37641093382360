.machine-tabs-wrap {
    display: flex;
    justify-content: space-between;
    width: 98%;
    padding: 0.83vw 0.4vw 0.4vw 0.62vw;
    align-items: center;
    border-top: 1px solid #4D4D4D;
    border-bottom: 1px solid #4D4D4D;

    .mode-container{
        display: flex;
        align-items: center;
        width: 72%;
        .mode-details{
            display: flex;
            flex-direction: column;
            margin-left: 0.62vw;
            margin-right: 0.62vw;

            .mode {
                color: white;
                font-size: 0.72vw;
                font-family: 'Noto Sans';
                font-weight: 500;
                white-space: nowrap;
                margin: 0px;
            }

            .mt-4{
                margin-top: 4px;
            }
        }
    }
    .device-select-btn-container {
        display: flex;
        width:25.5%;
        max-width: 25.5%;
        min-height: 1.66vw;
        justify-content: space-between;
        border-radius: 4px;
        background: #242424;
        
            .device-btn{
                width: 50%;
                max-width: 50%;
                padding: 0.42vw 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--DG5, #999);
                font-family: Noto Sans;
                font-size: 0.625vw;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: center;
                cursor: pointer;
                white-space: nowrap;
            }
            .disabled {
                opacity: 0.4;
                cursor: not-allowed;
            }
            .active-device-component {
                border-radius: 4px;
                background: var(--Primary, #6D1DF0);
                color: var(--White, #FFF);
            }
        
    }
}