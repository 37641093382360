.patient-right-panel-container {
    // overflow: scroll;
    height: 100%;
    width: 100%;

    .bed-info-container {
        display: flex;
        flex-direction: column;
        height: 10%;
        width: 100%;


        .bed-header {
            display: flex;
            justify-content: space-between;
            padding: 0.26vw 0.78vw;

            .cross-container {
                width: 1vw;
                height: 1vw;
                cursor: pointer;
                position: relative;
                right: 0.41vw;
                top: 0.52vw;
                // right: 0px;
                // top: 3px;
            }

            .bed-number {
                font-weight: 600;
                font-size: 0.83vw;
                line-height: 1.14vw;
                letter-spacing: -0.02em;
            }
        }

        .patient-details {
            display: flex;
            gap: 0.41vw;
            color: #9A9A9A;
            padding: 0px 0.78vw;
            font-size: 0.625vw;
        }

        .sub-header-switch {
            display: flex;
            font-weight: 600;
            font-size: 0.83vw;
            gap: 2vw;
            cursor: pointer;
            padding: 0.52vw 0.52vw 0px 0.52vw;
            background-color: black;

            .common-header {
                font-weight: 400;
                font-size: 0.83vw;
                line-height: 0.98vw;
                letter-spacing: -0.02em;
                color: #4D4D4D;
            }

            .active-header {
                padding-bottom: 0.26vw;
                border-bottom: 0.1vw solid #ffffff;
                color: #FFFFFF;
            }
        }

    }

    .active-subheader-body-container {
        height: 90%;
        box-sizing: border-box;
        overflow-y: auto;
    }

}