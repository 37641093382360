.side-bar {
  background-color: #0d0d0d;
  height: 100%;
  width: 4vw;
  z-index: 1000;

  .sidebar-icon-container {
    // padding-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.04vw;
    justify-content: space-between;
    height: 100%;
    border-right:  0.1vw solid #333333;
    .top-icon-container{ 
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2.5vw;

      img{
        height: 1.25vw;
        width: 1.25vw;
        padding-left: 1vw;
      }
      .activeIcon{
        border-left:0.26vw solid #6D1DF0;
      }
    }
    .bottom-icon-container {
      cursor: pointer;
    }
  }

  .icon-container {
    margin-bottom: 2.5vw;
  }
  .icon{
    height: 1.25vw;
    width: 1.25vw;
  }
}