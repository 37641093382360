.bedcard-monitor-container {
    width: 100%;

    .disabled {
        cursor: not-allowed !important;
    }
    .bedcard-monitor {
        // background: #0D0D0D;
        background: #141414;
        border-radius: 0.52vw;
        box-sizing: border-box;
        padding: 0.625vw 0.83vw;
        cursor: pointer;

        .bed-patient-details {
            .bed-header {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                align-items: baseline;
                line-height: 120%;

                .bed-number {
                    color: #FFF;
                    font-family:'Noto Sans';
                    font-size: 0.937vw;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: -0.0375vw;
                }
            }

            .high-priority {
                background: #AD0606;
                color: #fff !important;
            }

            .low-priority {
                background: #3ADAE5;
                color: #000;

            }

            .medium-priority {
                background: #FCC331;
                color: #000;
            }

            .icon-container {
                img{
                    height: 1.25vw;
                    width: 1.25vw;
                }
                cursor: pointer;
            }


            .no_risk {
                background: #299842;
            }

            .mild_risk {
                background: #fcc331;
            }

            .favorite-icon {
                grid-column: 16/16;
                .fav-icon {
                    width: 1.04vw !important;
                    height: 1.04vw !important;
                }
            }

            .no-alarm {
                color: #fff !important;
                border: 1px solid #333333;
            }

            .alarm-container {
                color: #000;
                font-family: 'Noto Sans';
                font-size: 0.729vw;
                position: relative;
                font-weight: 400;
                letter-spacing: -0.038vw;
                display: flex;
                padding: 0.2vw 0.52vw;
                justify-content: space-between;
                border-radius: 0.27vw;
                min-width: 10.69vw;
                line-height: 150%;
                margin-top: 0.41vw;

                .top-alarm-container {
                    max-width: 42ch;
                    overflow: hidden;
                    white-space: nowrap;
                    padding-right: 1ch;
                }

                .alarm-number-container {
                    display: flex;
                    cursor: pointer;
                    align-items: center;

                    .arrow-icon {
                        margin-left: 0.26vw;
                        width: 0.625vw;
                        height: 0.625vw;
                    }
                }

                .more-alarm-container {
                    position: absolute;
                    top: 110%;
                    background: #1A1A1A;
                    border: 0.03vw solid #333333;
                    border-radius: 0.2vw;
                    width: 100%;
                    padding: 0.2vw;
                    z-index: 1000;
                    left: 0px;
                    box-sizing: border-box;

                    .more-alarm {
                        padding: 0.26vw  0.625vw;
                        background: #333333;
                        margin-bottom: 0.1vw;
                        font-weight: 400;
                        font-size: 0.52vw;
                        line-height: 0.72vw;
                        color: #FFFFFF;

                        .alarm-time {
                            float: right;
                        }
                    }

                    .see-all-container {
                        text-align: center;
                        font-weight: 400;
                        font-size:  0.625vw;
                        line-height: 0.83vw;
                        padding: 0.36vw 0.83vw;
                        cursor: pointer;
                        color: #FFFFFF;
                    }
                }
            }

            .patient-details-wrapper {
                display: flex;
                align-items: center;
                margin-top: 0.55vw;
                font-size: 0.729vw;
                justify-content: space-between;
                max-width: 100%;
                color: #999999;

                .patient-details {
                    display: flex;
                    color: #999999;
                    font-family: 'Noto Sans';
                    font-size: 0.729vw;
                    font-style: normal;
                    align-items: center;
                    font-weight: 400;
                    letter-spacing: -0.038vw;
                    line-height: 120%;

                    .patient-name {
                        white-space: nowrap;
                    }
                }

                .no-patient-detail-container {
                    display: flex;
                    gap: 0.26vw;
                    align-items: center;

                    .add-patient-btn {
                        color: #6D1DF0;
                        text-decoration: underline;
                    }
                }

                .pipe {
                    margin-left: 0.41vw;
                    margin-right: 0.41vw;
                    color: #999999;
                }
            }
        }

        .hr-line {
            border: 0.052vw solid #333333;
        }
        .device-monitor-data{
            display: flex;
            color: #ffffff;
            text-align: center;
            justify-content: space-between;
            margin-top: 0.41vw;
            gap: 0.2vw;

            .vital-data-detail {
                box-sizing: border-box;
                background: #292929;
                padding: 0.6vw;
                padding-bottom: 0.27vw;
                border-radius: 0.14vw;
                height: 4.44vw;
                min-width: 16%;
                box-sizing: border-box;
            }
            .wide-field{
                width: 8.33vw !important;
            }
            .critical {
                background: #ad0606;
            }

            .vital-name {
                font-weight: 400;
                font-size: 0.54vw;
                line-height: 0.97vw;
            }

            .vital-data {
                display: flex;
                flex-direction: column;
                text-align: center;
                gap: 0.156vw;
                font-family: "Barlow";
                font-size: 1.04vw;
                font-style: normal;
                font-weight: 600;
                line-height: 1.25vw;
                letter-spacing: -2%;
                .unit {
                    font-weight: 400;
                    font-size: 0.54vw;
                    line-height: 0.57vw;
                    color: #999999;
                }
            }
        }

        .not-connected {
            opacity: 0.2;
        }

        .device-ventilator-data-wrapper{
            margin-top:  0.625vw;

            .high-priority {
                background: #AD0606;
                color: #fff !important;
            }

            .low-priority {
                background: #3ADAE5;
                color: #000;

            }

            .medium-priority {
                background: #FCC331;
                color: #000;
            }

            .icon-container {
                img{
                    height: 10.41vw;
                    width: 10.41vw;
                }
                cursor: pointer;
            }


            .no_risk {
                background: #299842;
            }

            .mild_risk {
                background: #fcc331;
            }

            .favorite-icon {
                grid-column: 16/16;
                .fav-icon {
                    width: 1.04vw !important;
                    height: 1.04vw !important;
                }
            }

            .no-alarm {
                color: #fff !important;
                border: 1px solid #333333;
            }

            .alarm-container {
                color: #000;
                font-family: 'Noto Sans';
                font-size: 0.729vw;
                position: relative;
                font-weight: 400;
                letter-spacing: -0.029vw;
                display: flex;
                padding: 0.156vw 0.52vw;
                justify-content: space-between;
                border-radius: 0.2vw;
                min-width: 8.02vw;
                line-height: 150%;
                margin-top: 0.41vw;

                .top-alarm-container {
                    max-width: 42ch;
                    overflow: hidden;
                    white-space: nowrap;
                    padding-right: 1ch;
                }
                
                .alarm-number-container {
                    display: flex;
                    cursor: pointer;
                    align-items: center;

                    .arrow-icon {
                        margin-left: 0.26vw;
                        width: 0.625vw;
                        height: 0.625vw;
                    }
                }

                .more-alarm-container {
                    position: absolute;
                    top: 110%;
                    background: #1A1A1A;
                    border: 0.03vw solid #333333;
                    border-radius: 0.2vw;
                    width: 100%;
                    padding: 0.2vw;
                    z-index: 1000;
                    left: 0px;
                    box-sizing: border-box;

                    .more-alarm {
                        padding: 0.26vw  0.625vw;
                        background: #333333;
                        margin-bottom: 0.1vw;
                        font-weight: 400;
                        font-size: 0.52vw;
                        line-height: 0.72vw;
                        color: #FFFFFF;

                        .alarm-time {
                            float: right;
                        }
                    }

                    .see-all-container {
                        text-align: center;
                        font-weight: 400;
                        font-size:  0.625vw;
                        line-height: 0.83vw;
                        padding: 0.36vw 0.83vw;
                        cursor: pointer;
                        color: #FFFFFF;
                    }
                }
            }

            .ventilator-mode{
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #999999;
                font-family: 'Noto Sans';
                font-size: 0.72vw;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.029vw;
                
                .flex-alignCenter {
                    display: flex;
                    align-items: center;
                }
                .dot {
                    width: 0.5vw;
                    height: 0.5vw;
                    border-radius: 0.25vw;
                    margin-left: 0.5vw;
                }
                .connected {
                    background-color: #299842;
                }
                .standby {
                    background-color: #fcc331;
                }
                .disconnected {
                    background-color: #f00;
                }
            }
            margin-top:  0.625vw;
            .device-ventilator-data{
                display: flex;
                color: #ffffff;
                text-align: center;
                justify-content: space-between;
                margin-top: 0.41vw;
                gap: 0.2vw;
                .wide-field{
                    width: 6.25vw !important;
                }
                .high-priority {
                    background: #AD0606 !important;
                    color: #fff !important;
                }
    
                .low-priority {
                    background: #3ADAE5 !important;
                    color: #000 !important;
    
                }
    
                .medium-priority {
                    border: 1px solid #FCC331 !important;
                    color: #fff !important;
                }
                .vital-data-detail {
                    box-sizing: border-box;
                    background: #292929;
                    padding: 0.6vw;
                    padding-bottom: 0.27vw;
                    border-radius: 0.14vw;
                    height: 4.44vw;
                    min-width: 16%;
                    box-sizing: border-box;
                
                    .wide-field{
                        width: 8.33vw !important;
                    }
                    .critical {
                        background: #ad0606;
                    }
        
                    .vital-name {
                        font-weight: 400;
                        font-size: 0.54vw;
                        line-height: 0.97vw;
                    }
        
                    .vital-data {
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        gap: 0.156vw;
                        font-family: "Barlow";
                        font-size: 1.04vw;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.25vw;
                        letter-spacing: -2%;
                        .unit {
                            font-weight: 400;
                            font-size: 0.54vw;
                            line-height: 0.57vw;
                            color: #999999;
                        }
                    }
                }
            }
        }
        .vital-details {
            height: 8.85vw;

            .device-heading {
                display: flex;
                gap: 0.52vw;

                .device-mode {
                    .ventilator-mode {
                        margin-left: 0.156vw;
                    }
                }
            }

            .device-name {
                font-weight: 400;
                font-size: 0.52vw;
                line-height: 0.72vw;
                color: #808080;
            }
            .select-vital-graph-container {
                display: flex;
                gap: 0.26vw;
                position: relative;
                cursor: pointer;
                width: 2.6vw;

                .selected-vital-name {
                    font-weight: 400;
                    font-size: 0.54vw;
                    line-height: 0.72vw;
                    letter-spacing: -0.02em;
                    width: 1.56vw;
                }

                .vital-graph-options {
                    position: absolute;
                    top: 1.04vw;
                    width: 9.58vw;
                    // height: 322px;
                    padding: 0.52vw;
                    background: rgba(52, 52, 52, 0.5);
                    border: 0.052vw solid #404040;
                    backdrop-filter: blur(40px);
                    border-radius: 0.31vw;
                    z-index: 10;

                    .common-vital-option {
                        padding: 0.52vw;
                        font-weight: 400;
                        font-size: 0.72vw;
                        line-height: 1.875vw;
                        &:hover{
                            background-color: #9F33FF;
                        }
                    }

                    .vital-graph-active {
                        background-color: #6D1DF0;
                        border-radius: 0.2vw;
                    }
                }
            }

            .graph-container {
                height: 4.16vw;
                display: flex;
                gap: 0.52vw;
                align-items: baseline;
                justify-content: space-around;

                .graph-rate-container {
                    align-self: center;

                    .rate-title {
                        font-weight: 600;
                        font-size: 1.25vw;
                        line-height: 1.71vw;
                    }
                }
            }
        }
    }
}

.selected-card {
    border: 0.052vw solid #6D1DF0;
    border-radius: 0.31vw;
}