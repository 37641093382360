.loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.offline {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
    padding: 2vw;
    overflow: auto;
}

.not-in-use-imge {
    width: 8.6vw;
}

.not-in-use-text{
    font-size: 0.94vw;
    width: '70%';
    line-height: 150%;
}

.ventilator-vitals-container{
    height: 92%;
    display: flex;

    .waveform-and-footer-container{
        width: 73%;
        padding-top: 0.52vw;
        border-right: 1px solid #333333;
        .standby {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 78%;
            text-align: center;
            padding: 2vw;
            overflow: auto;
        }
        .waveform-container{
            display: flex;
            flex-direction: column;
            width: 99%;
            height: calc(86% - 1px);
            max-height: 100%;
            overflow: auto;
            padding-left: 0.52vw;
            border-bottom: 1px solid #333333;
            .graph-waveform{
                width: 100%;
                height:100%;
                .graph{
                    width: 100%;
                }
            }
        }
    }
    .param-vitals-container{
        position: relative;
        width: 25%;
        padding-top: 0.3vw;
        padding-left: 0.52vw;
        
        .vital-param-boxes-container{
            display: flex;
            flex-direction: column;
            overflow: auto;
            height: 86%;
            
            .param-grid-representation {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            gap: 0.2vw;
            overflow: hidden;
            
            .non-graph-param{
                color: #FFF;
                padding-bottom: 2.4vw !important;
                .param-val{
                    color: #FFF !important;
                }
            }
            .high-priority {
                background: #AD0606 !important;
                color: #fff !important;
            }

            .low-priority {
                background: #3ADAE5 !important;
                color: #000 !important;

            }

            .medium-priority {
                background: #FCC331 !important;
                color: #000 !important;
            }
            .left-column-containers{
                display: flex;
                gap: 0.1vw;
                flex-direction: column;
                padding: 0.3vw 0.41vw;
                background: #242424;
                height: 100%;
                min-width: 50%;
                box-sizing: border-box;
                align-items: center;

                .left-box-header{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    .param-name{
                        .param-name-text{
                            font-family: 'Noto Sans';
                            font-size: 0.8vw;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.32px;
                        }
                        .param-unit-text{
                            text-align: left;
                            font-family: 'Noto Sans';
                            font-size: 0.57vw;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.2px;
                        }
                    }
                    .param-st-val{
                        text-align: right;
                        font-family: 'Barlow';
                        font-size: 0.83vw;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 0.97;
                        letter-spacing: -0.32px;
                    }
                    
                }
                
                .param-val{
                    // color: #3ADAE5;
                    text-align: center;
                    font-family: 'Barlow Condensed';
                    font-size: 3vw;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 0.8;
                    letter-spacing: -1.44px;
                    margin-top: -0.5vw;
                }
                .green-font{
                    color: #299842;
                }

                .blue-font{
                    color: #3ADAE5;
                }
                .yellow-font{
                    color: #FCC331;
                }
                .high-priority {
                    background: #AD0606 !important;
                    color: #fff !important;
                }
    
                .low-priority {
                    background: #3ADAE5 !important;
                    color: #000 !important;
    
                }
    
                .medium-priority {
                    background: #FCC331 !important;
                    color: #000 !important;
                }
            }
            .param-right-row{
                display: flex;
                flex-direction: column;
                gap: 0.1vw;
                min-width: 50%;
                justify-content: space-between;
                .right-column-containers{
                    background: #242424;
                    display: flex;
                    padding: 0.31vw 0.41vw;
                    // height: 61px;
                    box-sizing: border-box;
                    justify-content: space-between;
                    .right-box-param{
                        min-width: 30%;
                        .right-box-param-name{
                            font-family: Noto Sans;
                            font-size: 0.625vw;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.24px;
                        }
                        .right-box-param-unit{
                            font-family: 'Noto Sans';
                            font-size: 0.52vw;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.2px;
                        }
                    }
                    .right-param-val{
                        // color: var(--Tertiary, #3ADAE5);
                        text-align: center;
                        font-family: 'Barlow Condensed';
                        font-size: 2vw;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.88px;
                    }
                    .green-font{
                        color: #299842;
                    }
    
                    .blue-font{
                        color: #3ADAE5;
                    }
                    .yellow-font{
                        color: #FCC331;
                    }
                    .right-st-container{
                        display: flex;
                        flex-direction: column;
                        gap: 0.1vw;
                        align-items: flex-end;
                        min-width: 15%;
                        .param-st-val{
                            font-family: 'Barlow';
                            font-size: 0.62vw;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -0.24px;
                        }
                        
                        .high-priority {
                            background: #AD0606 !important;
                            color: #fff !important;
                        }
            
                        .low-priority {
                            background: #3ADAE5 !important;
                            color: #000 !important;
            
                        }
            
                        .medium-priority {
                            background: #FCC331 !important;
                            color: #000 !important;
                        }
                    }

                }
                
                .high-priority {
                    background: #AD0606 !important;
                    color: #fff !important;
                }
    
                .low-priority {
                    background: #3ADAE5 !important;
                    color: #000 !important;
    
                }
    
                .medium-priority {
                    background: #FCC331 !important;
                    color: #000 !important;
                }
            } 
            }
        }

        .pagination-bar {
            height: 14% !important;
        }
    }
}