.css-wfp5c7-singleValue {
    line-height: 150%;
}

.css-1xc3v61-indicatorContainer{
    width: 1.875vw;
    height: 1.875vw;
    padding: 0.42vw;
}

.css-12lsz6c-indicatorContainer {
    width: 1.875vw;
    height: 1.875vw;
    padding: 0.42vw;
}