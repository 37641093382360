.login-container {
    display: flex;
    height: 100%;
    width: 100%;

    .left-panel {
        width: 50%;
        background-image: url('/common/images/login.webp');
        background-repeat: no-repeat;
        background-size: cover;
        
        .content-container {
            height: 80%;
            width: 100%;

            .illustration-container {
                width: 100%;
                height: 65%;
                text-align: center;
                max-height: 70%;
                .illustration {
                    width: 75%;
                    max-width: 260px;
                }
            }
            .below-illustration-container{
                margin-top: 30px;
                .logo-container {
                    text-align: center;
                // margin-top: 40px;
                }

            .content {
                .heading {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                    text-align: center;
                    margin-top: 15px;
                    color: #FFFFFF;
                    margin-bottom: 59px;
                }

                .body {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
                    margin-top: 17px;
                }
            }

            .content-navigation-container {
                display: flex;
                align-items: center;
                width: 50%;
                justify-content: space-between;
                margin: 0 auto;

                .radio-button-container {
                    min-width: 120px;
                    text-align: center;

                    .navigate-input {
                        padding: 0px;
                        margin: 0px 5px;
                        width: 10%;
                        cursor: pointer;
                    }
                }

                .arrow {
                    cursor: pointer;
                }

            }
            }
            
        }
    }

    .right-panel {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .content-container {
            width: 70%;

            .logo-container {
                display: flex;
                align-items: center;
            }
            .login-logo {
                width: 7%;
                height: auto;
            }

            .sign-in-text-container {
                display: inline;
                font-weight: 600;
                font-size: 2vw;
                text-align: center;
                letter-spacing: -0.24px;
                margin-left: 12px;
            }

            .sub-text {
                color: #808080;
                font-size: 0.8vw;
                font-family: 'Public Sans';
                line-height: 150%;
            }

            .login-form-container {
                margin-top: 11%;

                input:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 50px  #1F1F1F inset; /* Change the color to your own background color */
                    -webkit-text-fill-color: white;
                }

                .login-input {
                    width: 100%;
                    height: 2vw;
                    box-sizing: border-box;
                    background: #1F1F1F;
                    border-radius: 8px;
                    border-style: none;
                    padding: 11px 16px;
                    font-size: 0.8vw;
                    color: #FFFFFF;
                    min-width: 142px;
                    margin-bottom: 6px;
                    margin-top: 10px;
                }

                .login-label {
                    padding-left: 10px;
                }

                .sign-in-botton-container {
                    margin-top: 2vw;

                    .sign-in-botton {
                        width: 100%;
                        height: 100%;
                        background: #6D1DF0;
                        border-radius: 6px;
                        border-style: none;
                        padding: 6px 12px;
                        color: #FFFFFF;
                        min-height: 40.5px;
                        cursor: pointer;
                    }

                    .disabled {
                        background: grey;
                        pointer-events: none;
                    }
                }
            }
        }

        .footer-container {
            min-width: 170px;
            // position: absolute;
            // bottom: 0;
            width: 31%;
            margin-top: min(13%, 70px);
            margin-left: -13.8%;

            .login-logo-vector {
                width: 100%;
            }
        }
    }
}