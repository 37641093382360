.vitals-data-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    
    .flex {
        display: flex;
    }
    .justify-between{
        justify-content: space-between;
    }

    .high-priority {
        background: #AD0606;
        color: #fff !important;
    }

    .low-priority {
        background: #3ADAE5;
        color: #000;

    }

    .medium-priority {
        background: #FCC331;
        color: #000;
    }

    .alarm-container {
        padding: 0.3vw 1.25vw;
        position: relative;
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        // min-width: 175px;
        min-width: 7.29vw;
        font-size: 0.72vw;
        font-weight: 500;
        width: 100%;
        line-height: 150%;

        .top-alarm-container {
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
        }

        .alarm-number-container {
            display: flex;
            cursor: pointer;

            .arrow-icon {
                margin-left: 5px;
            }
        }

        .more-alarm-container {
            position: absolute;
            top: 100%;
            background: #1F1F1F;
            width: 100%;
            padding: 0.52vw;
            z-index: 1000;
            left: 0px;
            box-sizing: border-box;

            .more-alarm {
                padding: 0.26vw 0.625vw;
                margin-bottom: 0.1vw;
                color: #FFFFFF;

                .alarm-time {
                    float: right;
                }
            }

            .see-all-container {
                background-color: #333333;
                text-align: center;
                padding: 0.46vw 0px;
                cursor: pointer;
            }
        }
    }
}