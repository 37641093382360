.trend-container {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    overflow: scroll;

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .generate-report-form {
        // width: 100%;
        background: black;
        border-radius: 12px;
        height: 100%;
        position: relative;
        
        .report-heading {
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
        }

        .report-input-fields {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            height: 85%;
            // overflow: scroll;
            gap: 15px 20px;
            padding: 16px 12px;

            .label {
                color: #999999;
                margin-bottom: 5px;
            }

            .form-input {
                .trend-date {
                    background: #141414;
                    height: 42px;
                    border-radius: 6px;
                }

            }

            .trend-input {
                min-height: 40px;
                height: 42px;
            }

            .parameter-select-container {
                grid-column: 1/4;
            }
            .trend-time-container{
                .time-container{
                    .trend-time{
                         .react-time-picker__inputGroup__amPm option{
                            background-color: #000000;
                        }
                    }
                }
            }

        }

        .submit-button-container {
            display: flex;
            height: 52px;
            align-items: center;
            height: 70px;
            cursor: pointer;

            .submit-button {

                margin-top: 8px;
                padding: 4% 25%;
                font-weight: 600;
                box-sizing: border-box;
                width: 100%;
                font-size: 16px;
                line-height: 22px;
                color: #FFFFFF;
                background: #6D1DF0;
                border-radius: 6px;
                text-align: center;
            }

            .disabled {
                background-color: grey;
                pointer-events: none;
            }
        }
    }

    .trend-data-container {
        // width: 100%;
        background: black;
        border-radius: 12px;
        height: 100%;
        padding: 24px 16px;

        .trend-data-header {
            display: flex;
            justify-content: space-between;

            .report-text {
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: -0.02em;
                color: #FFFFFF;
            }

            .change-trend-container {
                display: flex;
                gap: 30px;
                margin-right: 10px;


                .trend-change-icon {
                    cursor: pointer;
                }
            }
        }

        .patient-details-container {
            margin-top: 24px;

            .patient-details {
                display: flex;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #FFFFFF;
                margin-top: 8px;

                .pipe {
                    margin-left: 8px;
                    margin-right: 8px;
                }
            }
        }

        .trend-table-container {
            margin-top: 24px;
        }

        
    }
}