@import url('https://fonts.googleapis.com/css?family=Noto+Sans');

.App {
  background-color: #0d0d0d;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  // padding: 0% 2.5% 0% 1.3%;
  height: 100%;
  width: 100%;
  color: #ffffff;
  justify-content: space-between;
}

.svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

::-webkit-scrollbar {
  width: 1px;
  height: 2px;
}

::-webkit-scrollbar-button {
  background: "";
}

::-webkit-scrollbar-track-piece {
  background: "";
}

::-webkit-scrollbar-thumb {
  background: violet;
}

.blur {
  filter: blur(2px);
}

.error-msg {
  color: red;
}

.date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-width: 200px;

  .date-text {
    margin-right: 10px;
  }

  .react-date-picker {
    width: 100%;
    height: 100%;

    .react-date-picker__wrapper {
      background: #141414;
      border-radius: 6px;
      border-color: #0d0d0d;
      padding: 3px 5px;
    }

    .react-date-picker__inputGroup__input {
      color: #FFFFFF;
      ;
      font-size: 15px;
      // width: 30px;
    }

    .react-date-picker__clear-button {
      color: #FFFFFF;
      ;
    }

    .react-date-picker__calendar-button {
      color: #FFFFFF;
      ;
    }

    .react-date-picker__clear-button__icon {
      stroke: #808080;
    }

    .react-date-picker__clear-button__icon:hover {
      stroke: #FFFFFF;
    }

    .react-date-picker__calendar-button__icon {
      stroke: #808080;
    }

    .react-date-picker__calendar-button__icon:hover {
      stroke: #FFFFFF;
    }

    .react-date-picker__inputGroup__leadingZero {
      font-size: 15px;
    }
  }
}

.time-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .react-time-picker {
    width: 100%;
    height: 100%;

    .react-time-picker__wrapper {
      background: #141414;
      border-radius: 6px;
      border-color: #0d0d0d;
      padding: 3px 5px;
    }

    .react-time-picker__inputGroup__input {
      color: #FFFFFF;
      font-size: 15px;
      // width: 30px;
    }

    .react-time-picker__clear-button {
      color: #FFFFFF;
    }

    .react-time-picker__clock-button {
      color: #FFFFFF;
    }

    .react-time-picker__clear-button__icon {
      stroke: #808080;
    }

    .react-time-picker__clear-button__icon:hover {
      stroke: #FFFFFF;
    }

    .react-time-picker__clock-button__icon {
      stroke: #808080;
    }

    .react-time-picker__clock-button__icon:hover {
      stroke: #FFFFFF;
    }

    .react-time-picker__inputGroup__leadingZero {
      font-size: 15px;
    }
  }
}

.button-disabled {
  background: #CCCCCC !important;
  cursor: none;
  pointer-events: none;
}