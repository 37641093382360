.alarm-table-container {
  overflow: scroll;
  height: 100%;
  width: 100%;

  .alarm-table {
    width: 100%;
    // height: 100%;
    border-collapse: collapse;
    text-align: left;
    overflow: scroll;
    font-size: 14px;

    // td,
    // th {
    //   // border: 1px solid #4D4D4D;
    //   ;
    // }

    tr {
      border-bottom: 0.5px solid #333333;
    }
    .alarm-priority{
      padding-left: 20px;
    }
    .sticky {
      position: sticky;
      top: 0;
    }

    th {
      border-bottom: 1px solid black;
      background: #333333;
      padding: 12px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
      background-color: #141414;
    }

    td {
      text-align: left;
      background: #1f1f1f;
      padding: 12px;
      background: #0D0D0D;

      .view-trend {
        display: flex;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        color: #6D1DF0;
        gap: 5px;
      }
    }
  }
}