.container {
    height: 21vh;
    width: 100%;
}

.flow-container {
    height: 83vh;
    width: 100%;
}

.graph {
    padding-left: 0.5vw ;
    margin-bottom: 1.2vw;
}

.apex-graph-label {
    font-size: 0.72vw;
    color: white;
    font-family: 'Noto Sans';
    font-weight: 500;
    width: 100%;
    text-align: left;
    line-height: 1;
    margin-left: 10px
}