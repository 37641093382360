.header {
  // background-color: red;
  // height: 9.7%;
  width: 100%;
  height: 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0d0d0d;
  z-index: 1000;
  border-bottom: 2px solid #333333;
  // box-shadow: 1px 1px; //TODO: REMOVE IT

  .logo-container {
    padding-left: 1.042%;
    display: flex;
    align-items: center;
    height: 100%;
    
    .logo {
      width: 2.5vw;
      height: 1.93vw;
    }

    .dashboard-text {
      padding-left: 1.0416vw;
      color: var(--White, #FFF);
      font-family: Poppins;
      font-size: 1.458vw;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.0875vw;
    }
  }

  .ward-container {
    display: flex;
    align-items: center;
    padding-right: 36px;

    .hospital-name-container {
      // width: 150px;
      border-right: 0.052vw solid #808080;
      padding-right: 0.52vw;
      color: var(--White, #FFF);
      font-family: Public Sans;
      font-size: 1.0416vw;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.042vw;
      margin-right: 0.78vw;
    }

    .volume-icon-container {
      margin-right: 19px;
    }
  }
}