.alarm-history-container {
    height: 94%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 9px;
    .machine-tabs-wrap {
        border: 0px;
        .device-select-btn-container{
            width: 50%;
            max-width: 50%;
        }
    }
    .device-select-btn-cont {
        padding-left: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #4D4D4D;
        border-bottom: 1px solid #4D4D4D;;
        .filter-select{
            width: 210px;
        }
    }

    .alarm-history-header-container {
        padding-left: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;

        .alarm-history-text {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        }
    }

    .alarm-history-data-container {
        // margin-top: 24px;
        // height: 79%;
        height: 96%;
        padding-left: 18px;
        padding-right: 8px;
    }
}