.vital-footer-container{
    height: 11%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    width: 100%;

    .footer-param-box{
        min-width: 6.66vw;
        min-height: 3.64vw;
        padding: 0.2vw 0.1vw 0.26vw 0.3vw;
        text-align: center;
        gap: 0.05vw;
        .footer-param-name{
            color: var(--White, #FFF);
            text-align: center;
            font-family: 'Noto Sans';
            font-size: 0.625vw;
            min-height: 0.83vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.24px;
        }
        .footer-param-unit{
            text-align: center;
            font-family: 'Noto Sans';
            font-size: 0.625vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .footer-param-value{
            width: 100%;
            border-right: 1px solid #333;
            color: #FFF;
            text-align: center;
            font-family: 'Barlow Semi Condensed';
            font-size: 1.66vw;
            font-style: normal;
            font-weight: 500;
            line-height: 1.66vw;
            letter-spacing: -0.72px;
        }
    }
}

.vital-footer-container::-webkit-scrollbar {
    display: none;
  }
  
  .vital-footer-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }